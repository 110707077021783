@use "../utils" as *;
@use "../global" as *;

.section5 {
  display: flex;
  flex-direction: column;
}

.image {
  margin: 53px auto 0;
  width: 1055px;
  outline: 13.611709594726562px solid rgb(255 255 255);
  outline-offset: -6.805854797363281px;
  aspect-ratio: 1.37;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));

  @include xxl {
    width: 75.3%;
    height: 93.56%;
  }

  @include md {
    margin: 4% auto 0;
    width: 96.56%;
  }
}
