@use "../utils" as *;
@use "../global" as *;

.section3 {
  display: flex;
  flex-direction: column;
}

.image2 {
  margin: 0 auto;
  width: 37.05%;
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -6.389999866485596px;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));

  @include md {
    width: 85%;
  }
}
