@use "../utils" as *;
@use "../global" as *;

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  overflow: hidden;
}

.cover {
  position: absolute;
  width: 100%;
  height: 100%;
}

.section1 {
  display: flex;
  flex-direction: column;
  z-index: 2024;
}

.section11 {
  //position: fixed;
  display: flex;
  flex-direction: column;
  height: 260px;
  background-color: rgb(255 255 255);
  box-shadow: 0 0 22px 5px rgb(0 0 0 / 25%);
  width: 100%;

  @include xxl {
    height: 250px;
  }

  @include lg {
    height: 210px;
  }

  @include md {
    width: 100%;
    height: 150px;
  }

  @include sm {
    height: 100px;
  }

  @include xxs {
    height: 80px;
  }

  @include tn {
    height: 65px;
  }
}

.flex_col {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px auto 0;
  width: 66%;

  @include xxl {
    margin: 8px auto 10px;
    width: 80%;
  }

  @include xl {
    width: 90%;
  }

  @include lg {
    width: 95%;
  }

  @include md {
    width: 100%;
  }

  @include sm {
    margin: 0 auto;
    height: 100%;
  }

  @include xxs {
    height: 80px;
  }

  @include tn {
    height: 65px;
  }
}

.flex_row {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  width: 98.46%;

  @include lg {
    height: 140px;
  }

  @include md {
    width: 100%;
  }

  @include sm {
    height: 100px;
  }

  @include xxs {
    column-gap: 10px;
    height: 80px;
  }

  @include tn {
    height: 65px;
  }
}

.image12 {
  width: 129px;
  min-width: 0;
  margin-top: 2px;

  @include lg {
    height: 140px;
  }

  @include md {
    margin-left: 2px;
    margin-top: 4px;
  }

  @include sm {
    height: 100px;
    width: 90px;
  }

  @include xxs {
    flex: 0 1 auto;
    width: 60px;
    height: 70px;
  }

  @include tn {
    width: 50px;
    height: 60px;
  }
}

.image13 {
  width: 120px;
  height: 140px;
  float: right;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 20px;

  @include sm {
    height: 95px;
    width: 90px;
  }

  @include xxs {
    height: 75px;
    width: 70px;
    margin-bottom: 10px;
  }

  @include tn {
    height: 60px;
    width: 55px;
  }
}

.flex_col1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px 0;
  margin: 27px 0 22px;
  width: 787px;
  min-width: 0;

  @include lg {
    margin: 15px 0 22px;
  }

  @include sm {
    align-items: stretch;
    column-gap: 0;
  }

  @include xxs {
    flex: 0 1 auto;
    margin: 5px 0 0;
    width: 81%;
    gap: 5px 0;
  }
  @include tn {
    gap: 0 0;
  }
}

.flex_col6 {
  display: flex;
  flex-direction: column;

  @include md {
    width: 100px;
    margin-right: 10px;
  }

  @include xs {
    gap: 15px 0;
  }

  @include xxs {
    gap: 0 0;
    width: 60px;
  }

  @include tn {
    width: 50px;
    margin-right: 3px;
  }
}

.hamburger {
  display: none;

  @include md {
    display: flex;
    margin-left: 60px;
    margin-top: 20px;
  }

  @include sm {
    margin-left: 40px;
    margin-top: 15px;
  }

  @include xs {
    margin-left: 20px;
    margin-top: 0;
  }

  @include tn {
    margin-left: 5px;
  }
}

.hamburgerDiv {
  position: fixed;
  z-index: 0;
  right: 0;
  height: 320px;
  width: 200px;

  @include sm {
    width: 190px;
    height: 270px;
  }

  @include xs {
    width: 170px;
  }

  @include xxs {
    width: 150px;
    height: 240px;
  }

  @include tn {
    width: 130px;
    height: 210px;
  }
}

.title3 {
  font: 500 31.87px/1.14 Montserrat, Helvetica, Arial, serif;
  color: rgb(0 0 0);
  letter-spacing: 4.13px;

  @include md {
    font-size: 25px;
  }

  @include sm {
    font-size: 16px;
  }

  @include xs {
    font-size: 10px;
  }

  @include xxs {
    font-size: 11px;
    letter-spacing: 2px;
  }

  @include tn {
    font-size: 9px;
  }
}

.title3_box {
  width: 100%;

  @include md {
    align-items: flex-start;
  }
}

.title3_span0 {
  font: 500 0.9998541773020155em/1.14 Montserrat, Helvetica, Arial, serif;
}

.title3_span1 {
  font: 700 0.9998541773020155em/1.14 Montserrat, Helvetica, Arial, serif;
}

.title {
  font: 500 28px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include lg {
    align-items: flex-start;
    width: 100%;
    font-size: 18px;
  }

  @include sm {
    width: 110%;
    font-size: 15px;
  }

  @include xs {
    font-size: 12px;
  }

  @include xxs {
    width: 75%;
    font-size: 10px;
  }

  @include tn {
    width: 100%;
    font-size: 8px;
  }
}

.a_col {
  @include md {
    width: 100%;
    margin-right: 10px;
  }
}

.image9 {
  margin: 101px 0 14px;
  width: 101px;
  min-width: 0;
  height: 50px;

  @include lg {
    margin: 43px 0 5px;
  }

  @include md {
    margin: 0 0 0;
    height: 50px;
  }

  @include sm {
    width: 80px;
    height: 35px;
  }

  @include xs {
    width: 60px;
    height: 30px;
  }

  @include xxs {
    width: 40px;
    height: 18px;
    margin-left: 35%;
  }

  @include tn {
    width: 35px;
    height: 18px;
    margin-left: 25%;
  }
}

.flex_row1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 20px;
  width: 100%;
  background-color: rgb(255 255 255);
  @include xxl {
    justify-content: stretch;
    align-items: start;
    column-gap: 20px;
  }

  @include xl {
    justify-content: flex-start;
    column-gap: 40px;
  }

  @include lg {
    place-content: stretch center;
    column-gap: 10px;
  }

  @include md {
    display: none;
  }
}

.flex_col2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 193px;
  min-width: 0;

  @include xl {
    width: 15%;
  }

  @include lg {
    width: 16%;
  }
}

.rect13 {
  width: 100%;
  min-height: 10px;
  background-color: rgb(241 241 241);

  @include xl {
    margin: 0 0 0 10px;
  }
}

.title4 {
  font: 600 29.93px/1.2 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include xl {
    align-items: flex-start;
    font-size: 27px;
  }

  @include lg {
    font-size: 25px;
  }
}

.flex_col3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 169px;
  min-width: 0;

  @include xl {
    width: 13%;
  }

  @include lg {
    width: 15%;
  }
}

.rect12 {
  width: 100%;
  min-height: 10px;
  background-color: rgb(241 241 241);

  @include xl {
    margin: 0 0 0 10px;
  }
}

.flex_col4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 271px;
  min-width: 0;

  @include xl {
    width: 20%;
  }

  @include lg {
    width: 23%;
  }
}

.rect11 {
  width: 100%;
  min-height: 10px;
  background-color: rgb(241 241 241);

  @include xl {
    margin: 0 0 0 10px;
  }

  @include lg {
    width: 98%;
  }
}

.flex_col5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 516px;
  min-width: 0;

  @include xl {
    width: 40%;
  }

  @include lg {
    width: 42%;
  }
}

.rect10 {
  width: 98.84%;
  min-height: 10px;
  background-color: rgb(241 241 241);

  @include xl {
    margin: 0 0 0 10px;
    width: 100%;
  }
}

.title5 {
  font: 600 29.93px/1.2 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include xl {
    align-items: flex-start;
    font-size: 27px;
  }

  @include lg {
    font-size: 25px;
  }
}

.br1 {
}

.rect101 {
  width: 99%;
  min-height: 1px;
  background-color: rgb(206, 197, 197);
}

.flex_row11 {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px 10px;
  width: 100%;
}

.flex_col51 {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 90%;
  min-width: 0;
  gap: 5px 10px;
}

.title41 {
  font: 600 29.93px/1.2 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  font-size: 18px;
  text-align: right;

  @include xxs {
    font-size: 17px;
  }

  @include tn {
    font-size: 15px;
  }
}