@use "../utils" as *;
@use "../global" as *;

.section4 {
  display: flex;
  flex-direction: column;
}

.flex_row {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  margin: 42px auto 0;
  width: 71.35%;

  @include xxl {
    column-gap: 35px;
  }

  @include md {
    flex-direction: column;
    align-items: center;
    gap: 50px 25px;
    width: 90%;
  }

  @include xs {
    gap: 50px 20px;
  }
}

.flex_col {
  display: flex;
  flex-direction: column;
  gap: 54px 0;
  width: 758px;
  min-width: 0;

  @include md {
    margin-top: unset;
    margin-bottom: unset;
    width: 100%;
  }
}

.image4 {
  margin: 0 0 0 2px;
  width: calc(100% - 2px);
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -6.389999866485596px;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));

  @include tn {
    width: 100%;
    height: 100%;
  }
}

.image41 {
  margin: 0 2px 0 0;
  width: calc(100% - 2px);
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -6.389999866485596px;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));

  @include tn {
    width: 100%;
    height: 100%;
  }
}

.image6 {
  margin: 0 2px 0 0;
  width: calc(100% - 2px);
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -6.389999866485596px;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));

  @include tn {
    width: 100%;
    height: 100%;
  }
}

.flex_col1 {
  display: flex;
  flex-direction: column;
  gap: 56px 0;
  width: 560px;
  min-width: 0;

  @include md {
    margin-top: unset;
    margin-bottom: unset;
    width: 100%;
  }
}

.image5 {
  width: 100%;
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -6.389999866485596px;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));

  @include tn {
    height: 100%;
  }
}

.image7 {
  margin: 0 1px 0 0;
  width: calc(100% - 1px);
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -6.389999866485596px;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));

  @include tn {
    width: 100%;
    height: 100%;
  }
}
