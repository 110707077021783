@use "../utils" as *;
@use "../global" as *;

.section3 {
  display: flex;
  flex-direction: column;
}

.flex_col {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto 0;
  width: 83.18%;

  @include xxl {
    margin: 35px auto 0;
  }

  @include xl {
    margin: 30px auto 0;
  }

  @include lg {
    margin: 25px auto 0;
  }

  @include md {
    width: 90%;
  }

  @include sm {
    margin: 20px auto 0;
  }

  @include tn {
    margin: 15px auto 0;
  }
}

.flex_row {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  width: 97.68%;

  @include xxl {
    column-gap: 20px;
  }

  @include md {
    flex-direction: column;
    align-items: center;
    gap: 8px 20px;
  }
}

.flex_col1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px 0;
  margin: 0 0 1px;
  width: 756px;
  min-width: 0;

  @include md {
    gap: 10px 0;
    margin-top: unset;
    margin-bottom: unset;
    width: 100%;
  }

  @include xxs {
    gap: 8px 0;
  }

  @include tn {
    gap: 5px 0;
  }
}

.image7 {
  width: 100%;
  outline: 9.681041717529297px solid rgb(255 255 255);
  outline-offset: -4.840520858764648px;
  aspect-ratio: 1.38;
  filter: drop-shadow(0 0 8px rgb(0 0 0 / 25%));
}

.title1 {
  font: 600 28px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-align: center;
  text-shadow: 0 0 13px rgb(0 0 0 / 25%);

  @include lg {
    font-size: 25px;
  }

  @include md {
    font-size: 18px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }

  @include tn {
    font-size: 12px;
  }
}

.title1_box {
  display: flex;
  justify-content: center;
  width: 100%;

  @include lg {
    align-items: flex-start;
  }
}

.title1_span0 {
  font: 600 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title1_span1 {
  font: 500 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.flex_col2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px 0;
  margin: 2px 0 0;
  width: 756px;
  min-width: 0;

  @include md {
    gap: 12px 0;
    margin: 25px 0 0;
    width: 100%;
  }

  @include xs {
    margin: 20px 0 0;
  }

  @include xxs {
    gap: 10px 0;
  }

  @include tn {
    gap: 6px 0;
  }
}

.image8 {
  width: 100%;
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -6.389999866485596px;
  aspect-ratio: 1.39;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));
}

.title1_span11 {
  font: 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.flex_col3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px 0;
  margin: 47px 0 0;
  width: 47.34%;

  @include md {
    gap: 12px 0;
    margin: 25px 0 0;
    width: 100%;
  }

  @include sm {
    gap: 11px 0;
  }

  @include xxs {
    gap: 10px 0;
    margin: 20px 0 0;
  }

  @include tn {
    gap: 8px 0;
  }
}

.image9 {
  width: 100%;
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -6.389999866485596px;
  aspect-ratio: 1.38;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));
}

.flex_row1 {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  margin: 48px 0 0;
  width: 100%;

  @include xxl {
    column-gap: 20px;
  }

  @include md {
    flex-direction: column;
    align-items: center;
    gap: 8px 20px;
    margin: 25px 0 0;
  }

  @include xxs {
    margin: 20px 0 0;
  }
}

.flex_col4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 27px 0;
  width: 756px;
  min-width: 0;

  @include md {
    gap: 12px 0;
    margin-top: unset;
    margin-bottom: unset;
    width: 100%;
  }

  @include xs {
    gap: 10px 0;
  }

  @include xxs {
    gap: 8px 0;
  }
}

.image10 {
  width: 100%;
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -6.389999866485596px;
  aspect-ratio: 1.46;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));
}

.title2 {
  font: 600 28px/1.14 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-align: center;
  text-shadow: 0 0 13px rgb(0 0 0 / 25%);

  @include lg {
    font-size: 25px;
  }

  @include md {
    font-size: 18px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }

  @include tn {
    font-size: 12px;
  }
}

.title2_box {
  display: flex;
  justify-content: center;
  width: 100%;

  @include lg {
    align-items: flex-start;
  }
}

.title2_span0 {
  font: 600 1em/1.14 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title2_span1 {
  font: 500 1em/1.14 "Montserrat Alternates", Helvetica, Arial, serif;
}

.flex_col5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px 0;
  margin: 0 0 4px;
  width: 789px;
  min-width: 0;

  @include md {
    gap: 13px 0;
    margin: 25px 0 0;
    width: 100%;
  }

  @include sm {
    gap: 11px 0;
  }

  @include xxs {
    gap: 9px 0;
    margin: 20px 0 0;
  }

  @include tn {
    gap: 7px 0;
  }
}

.image91 {
  width: 95.82%;
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -6.389999866485596px;
  aspect-ratio: 1.38;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));
}

.title1_span2 {
  font: 600 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.flex_col6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 46px 0;
  margin: 48px 0 0;
  width: 97.68%;

  @include md {
    gap: 13px 0;
    margin: 25px 0 0;
  }

  @include xs {
    gap: 11px 0;
  }

  @include xxs {
    gap: 9px 0;
    margin: 20px 0 0;
  }
}

.flex_row2 {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  width: 100%;

  @include xxl {
    column-gap: 26px;
  }

  @include xl {
    column-gap: 22px;
  }

  @include lg {
    column-gap: 20px;
  }

  @include md {
    flex-direction: column;
    align-items: center;
    gap: 26px 20px;
  }

  @include sm {
    gap: 22px 20px;
  }

  @include xs {
    gap: 20px;
  }

  @include xxs {
    gap: 18px 20px;
  }
}

.image11 {
  width: 752px;
  min-width: 0;
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -6.389999866485596px;
  aspect-ratio: 1.38;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));

  @include md {
    margin-top: unset;
    margin-bottom: unset;
    width: 100%;
  }
}

.image6 {
  width: 756px;
  min-width: 0;
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -6.389999866485596px;
  aspect-ratio: 1.39;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));

  @include md {
    margin-top: unset;
    margin-bottom: unset;
    width: 100%;
  }
}

.flex_row3 {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  margin: 52px 0 0;
  width: 97.87%;

  @include xxl {
    column-gap: 26px;
  }

  @include xl {
    column-gap: 22px;
  }

  @include lg {
    column-gap: 20px;
  }

  @include md {
    flex-direction: column;
    align-items: center;
    gap: 8px 20px;
    margin: 25px 0 0;
  }

  @include xxs {
    margin: 20px 0 0;
  }
}

.flex_col7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 27px 0;
  width: 756px;
  min-width: 0;

  @include md {
    gap: 12px 0;
    margin-top: unset;
    margin-bottom: unset;
    width: 100%;
  }

  @include sm {
    gap: 11px 0;
  }

  @include xs {
    gap: 10px 0;
  }

  @include xxs {
    gap: 9px 0;
  }

  @include tn {
    gap: 8px 0;
  }
}

.image61 {
  width: 100%;
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -6.389999866485596px;
  aspect-ratio: 1.39;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));
}

.flex_col8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 27px 0;
  width: 756px;
  min-width: 0;

  @include md {
    gap: 11px 0;
    margin: 25px 0 0;
    width: 100%;
  }

  @include xs {
    gap: 10px 0;
  }

  @include xxs {
    gap: 9px 0;
    margin: 20px 0 0;
  }

  @include tn {
    gap: 8px 0;
  }
}
