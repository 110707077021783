@use "../utils" as *;
@use "../global" as *;

.section6 {
  display: flex;
  flex-direction: column;
}

.content_box2 {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 46px auto 0;
  width: 70%;
  background-color: rgb(139 139 139 / 20.3%);

  @include xxl {
    width: 80%;
  }

  @include md {
    margin: 35px auto 0;
    width: 90%;
  }

  @include sm {
    margin: 30px auto 0;
  }

  @include xxs {
    margin: 25px auto 0;
  }
}

.flex_col {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 55px auto 50px;
  width: 91.47%;

  @include xxl {
    margin: 45px auto 40px;
  }

  @include xl {
    margin: 40px auto 35px;
  }

  @include lg {
    margin: 35px auto 30px;
  }

  @include md {
    margin: 30px auto 25px;
  }

  @include xxs {
    margin: 25px auto 20px;
  }
}

.flex_row {
  display: flex;
  align-items: center;
  gap: 0 55px;

  @include xxl {
    width: 100%;
  }

  @include md {
    flex-direction: column;
    gap: 20px 55px;
  }
}

.image2 {
  width: 430px;
  min-width: 0;
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -6.389999866485596px;

  @include md {
    margin-top: unset;
    margin-bottom: unset;
    width: 100%;
  }
}

.flex_col1 {
  display: flex;
  flex-direction: column;
  gap: 13px 0;
  width: 60%;
  min-width: 0;

  @include md {
    margin-top: unset;
    margin-bottom: unset;
    width: 100%;
  }
}

.box5 {
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0 0 13px rgb(0 0 0 / 27%));
}

.big_title {
  width: 100%;
  font: 700 36px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include sm {
    align-items: flex-start;
    font-size: 29px;
  }

  @include xs {
    font-size: 24px;
  }

  @include xxs {
    font-size: 19px;
  }
}

.title {
  margin: 0 0 0 30px;
  width: 95%;
  font: 500 28px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include lg {
    align-items: flex-start;
    margin: 0 0 0 20px;
    font-size: 25px;
  }

  @include md {
    margin-left: unset;
    font-size: 20px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }

  @include tn {
    font-size: 12px;
  }
}

.box4 {
  display: flex;
  flex-direction: column;
  width: 64.47%;
  filter: drop-shadow(0 0 13px rgb(0 0 0 / 27%));
}

.flex_row1 {
  display: flex;
  gap: 0 8px;
  margin: 0 0 0 30px;
  width: 100%;

  @include lg {
    margin: 0 0 0 20px;
  }

  @include md {
    margin-left: unset;
  }
}

.image3 {
  margin: 10px 0 0;
  width: 60px;
  min-width: 0;
  height: 60px;

  @include md {
    margin-top: unset;
    width: 50px;
    height: 50px;
  }

  @include xs {
    width: 40px;
    height: 40px;
  }
}

.title2 {
  display: flex;
  margin: 0 0 2px;
  width: 86.12%;
  min-width: 0;
  font: 400 28px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include lg {
    align-items: flex-start;
    font-size: 25px;
  }

  @include md {
    align-items: center;
    font-size: 22px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }

  @include tn {
    font-size: 12px;
  }
}

.btn {
  display: flex;
  justify-content: center;
  margin: 0 0 0 8px;
  width: 211px;
  max-width: 100%;
  font: 700 30.3px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-align: center;
  background-color: rgb(255 255 255);
  border-radius: 25.5px;
  padding: 7px 12px;
  filter: drop-shadow(0 0 13px rgb(0 0 0 / 27%));

  @include xs {
    align-items: flex-start;
    margin: 0;
    font-size: 25px;
  }

  @include xxs {
    width: 180px;
    font-size: 20px;
  }

  @include tn {
    width: 150px;
    font-size: 18px;
  }
}

.flex_row2 {
  display: flex;
  align-items: center;
  gap: 0 55px;
  margin: 63px 0 0;

  @include xxl {
    width: 100%;
  }

  @include md {
    flex-direction: column;
    gap: 20px 55px;
    margin: 40px 0 0;
  }
}

.image21 {
  width: 430px;
  min-width: 0;
  height: 430px;
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -12.779999732971191px;

  @include md {
    margin-top: unset;
    margin-bottom: unset;
    width: 100%;
  }

  @include xs {
    border-style: none;
  }
}

.flex_col2 {
  display: flex;
  flex-direction: column;
  gap: 14px 0;
  width: 60%;
  min-width: 0;

  @include md {
    margin-top: unset;
    margin-bottom: unset;
    width: 100%;
  }
}

.flex_col3 {
  display: flex;
  flex-direction: column;
  gap: 1px 0;
}

.title1 {
  margin: 0 0 0 30px;
  width: 95%;
  font: 500 28px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 13px rgb(0 0 0 / 27%);

  @include lg {
    align-items: flex-start;
    margin: 0 0 0 20px;
    font-size: 25px;
  }

  @include md {
    margin-left: unset;
    font-size: 22px;
  }

  @include xs {
    font-size: 15px;
  }

  @include tn {
    font-size: 12px;
  }
}

.box3 {
  display: flex;
  flex-direction: column;
  width: 71.47%;
  filter: drop-shadow(0 0 13px rgb(0 0 0 / 27%));
}

.flex_row3 {
  display: flex;
  gap: 0 8px;
  margin: 0 0 0 30px;

  @include lg {
    margin: 0 0 0 20px;
  }

  @include md {
    margin-left: unset;
  }
}

.title21 {
  display: flex;
  margin: 0 0 2px;
  width: 87.76%;
  min-width: 0;
  font: 400 28px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include md {
    align-items: center;
    font-size: 22px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }

  @include tn {
    font-size: 12px;
  }
}

.btn1 {
  display: flex;
  justify-content: center;
  width: 211px;
  max-width: 100%;
  font: 700 30.3px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-align: center;
  background-color: rgb(255 255 255);
  border-radius: 25.5px;
  padding: 7px 12px;
  filter: drop-shadow(0 0 13px rgb(0 0 0 / 27%));

  @include xs {
    align-items: flex-start;
    font-size: 25px;
  }

  @include xxs {
    width: 180px;
    font-size: 20px;
  }

  @include tn {
    width: 150px;
    font-size: 18px;
  }
}

.flex_row4 {
  display: flex;
  align-items: center;
  gap: 0 55px;
  margin: 64px 0 0 6px;
  width: 84.18%;

  @include xxl {
    column-gap: 55px;
    width: 100%;
  }

  @include md {
    flex-direction: column;
    gap: 20px 55px;
    margin: 40px 0 0 0;
  }

  @include tn {
    margin: 40px 0 0;
  }
}

.rect5 {
  width: 417px;
  min-width: 0;
  height: 417px;
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -6.389999866485596px;
  aspect-ratio: 1;

  @include xxl {
    width: 35%;
    max-width: 100%;
    height: 35%;
  }

  @include md {
    margin-top: unset;
    margin-bottom: unset;
    width: 100%;
  }
}

.box2 {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 3px;
  filter: drop-shadow(0 0 13px rgb(0 0 0 / 27%));
}

.flex_col4 {
  display: flex;
  flex-direction: column;
  margin: 0 3px 0 0;
}

.title3 {
  margin: 0 0 0 30px;
  width: 95%;
  font: 500 28px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include lg {
    align-items: flex-start;
    margin: 0 0 0 20px;
    font-size: 25px;
  }

  @include md {
    margin-left: unset;
    font-size: 22px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }

  @include tn {
    font-size: 12px;
  }
}

.box1 {
  display: flex;
  gap: 0 5px;
  margin: 0 25px 0 30px;
  filter: drop-shadow(0 0 13px rgb(0 0 0 / 27%));

  @include lg {
    margin: 0 25px 0 20px;
  }

  @include md {
    margin: 0 25px 0 0;
  }
}

.image31 {
  margin: 4px 0;
  width: 60px;
  min-width: 0;
  height: 60px;

  @include md {
    margin: 0 0 4px;
    width: 50px;
    height: 50px;
  }

  @include xs {
    margin-top: unset;
    margin-bottom: unset;
    width: 40px;
    height: 40px;
  }
}

.title22 {
  display: flex;
  width: 88.24%;
  min-width: 0;
  font: 400 28px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include lg {
    align-items: flex-start;
    font-size: 25px;
  }

  @include md {
    align-items: center;
    font-size: 22px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }

  @include tn {
    font-size: 12px;
  }
}

.hero_title1 {
  position: absolute;
  top: -30px;
  left: -14px;
  width: 100%;
  height: 59px;
  font: 700 48px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include md {
    top: -15px;
    left: -16px;
    align-items: flex-start;
    font-size: 26px;
  }

  @include xs {
    top: -12px;
    left: -10px;
    font-size: 20px;
  }

  @include tn {
    top: -8px;
    font-size: 15px;
  }
}
