@use "../utils" as *;
@use "../global" as *;

.section3 {
  display: flex;
  flex-direction: column;
}

.content_box {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 60px auto 0;
  width: 70%;
  background-color: rgb(139 139 139 / 20.3%);

  @include xxl {
    margin: 40px auto 0;
    width: 80%;
  }

  @include xl {
    margin: 35px auto 0;
  }

  @include md {
    margin: 25px auto 0;
    width: 90%;
  }
}

.title {
  font: 500 28px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 13px rgb(0 0 0 / 27%);

  @include lg {
    font-size: 25px;
  }

  @include md {
    font-size: 18px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }

  @include tn {
    font-size: 12px;
  }
}

.title_box {
  position: relative;
  margin: 30px auto 15px;
  width: 90%;

  @include lg {
    align-items: flex-start;
  }

  @include md {
    margin: 20px auto 15px;
  }

  @include xs {
    margin: 10px auto 15px;
  }
}

.title_span0 {
  font: 500 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title_span1 {
  font: 600 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title_span2 {
  font: 500 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title_span3 {
  font: 600 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.hero_title1 {
  position: absolute;
  top: -30px;
  left: -12px;
  width: 428px;
  height: 59px;
  font: 700 48px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 13px rgb(0 0 0 / 27%);

  @include md {
    top: -15px;
    left: -14px;
    align-items: flex-start;
    width: 100%;
    font-size: 26px;
  }

  @include xs {
    font-size: 20px;
  }

  @include tn {
    font-size: 15px;
  }
}
