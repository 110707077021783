@use "../utils" as *;
@use "../global" as *;

.section2 {
  display: flex;
  flex-direction: column;
}

.content_box {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 70px auto 53px;
  width: 70%;
  background-color: rgb(139 139 139 / 20.3%);

  @include xxl {
    margin: 50px auto 53px;
    width: 80%;
  }

  @include xl {
    margin: 40px auto 53px;
  }

  @include lg {
    margin: 35px auto 53px;
    width: 85%;
  }

  @include md {
    margin: 30px auto 53px;
    width: 90%;
  }

  @include sm {
    margin: 25px auto 53px;
  }

  @include tn {
    margin: 20px auto 53px;
  }
}

.title1 {
  font: 500 28px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 12px rgb(0 0 0 / 25%);

  @include lg {
    font-size: 25px;
  }

  @include md {
    font-size: 18px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }

  @include tn {
    font-size: 12px;
  }
}

.title1_box {
  position: relative;
  margin: 33px auto 9px;
  width: 90.58%;

  @include lg {
    align-items: flex-start;
  }

  @include md {
    margin: 25px auto 9px;
  }

  @include sm {
    margin: 20px auto 9px;
  }

  @include xs {
    margin: 15px auto 9px;
  }

  @include tn {
    margin: 10px auto 9px;
  }
}

.title1_span0 {
  font: 500 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title1_span1 {
  font: 600 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(24 115 189);
}

.title1_span2 {
  font: 500 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title1_span3 {
  font: 600 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title1_span4 {
  font: 500 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title1_span5 {
  font: 600 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(24 115 189);
}

.title1_span6 {
  font: 500 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.hero_title1 {
  position: absolute;
  top: -30px;
  left: -38px;
  display: flex;
  justify-content: center;
  width: 60%;
  height: 59px;
  font: 700 48px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-align: center;
  text-shadow: 0 0 12px rgb(0 0 0 / 25%);

  @include xxxl {
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    font-size: 48px;
  }

  @include md {
    top: -15px;
    left: -20px;
    font-size: 26px;
  }

  @include xs {
    top: -12px;
    left: -12px;
    font-size: 20px;
  }

  @include tn {
    top: -10px;
    left: -11px;
    font-size: 15px;
  }
}
