@use "../utils" as *;
@use "../global" as *;

.section3 {
  display: flex;
  flex-direction: column;
}

.flex_col {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 60%;

  @include md {
    width: 90%;
  }
}

.image1 {
  width: 98.78%;
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -6.389999866485596px;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));
}

.image {
  margin: 61px 0 0;
  width: 68.35%;
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -6.389999866485596px;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));
}

.image11 {
  margin: 53px 0 0;
  width: 98.78%;
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -12.779999732971191px;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));
}
