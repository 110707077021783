@use "../utils" as *;
@use "../global" as *;

.section3 {
  display: flex;
  flex-direction: column;
}

.flex_col {
  display: flex;
  flex-direction: column;
  gap: 54px 0;
  margin: 53px auto 0;
  width: 879px;

  @include md {
    gap: 40px 0;
    margin: 35px auto 0;
    width: 90%;
  }

  @include xs {
    gap: 30px 0;
  }

  @include tn {
    gap: 25px 0;
  }
}

.image {
  width: 100%;
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -6.389999866485596px;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));
}
