@use "../utils" as *;
@use "../global" as *;

.group {
  display: flex;
  flex-direction: column;
  background-color: rgb(255 255 255);
}

.group1 {
  position: relative;
  display: flex;
  flex-direction: column;
}

.cover {
  position: absolute;
  width: 100%;
  height: 100%;

  @include xs {
    top: 0;
  }
}

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
