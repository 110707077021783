@use "../utils" as *;
@use "../global" as *;

.section2 {
  display: flex;
  flex-direction: column;
}

.content_box {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 60px auto 50px;
  width: 70%;
  background-color: rgb(139 139 139 / 20.3%);

  @include xxl {
    margin: 45px auto;
  }

  @include xl {
    margin: 40px auto;
    width: 80%;
  }

  @include md {
    margin: 25px auto 35px;
    width: 90%;
  }

  @include sm {
    margin: 25px auto 30px;
  }

  @include xxs {
    margin: 20px auto 30px;
  }
}

.title {
  font: 600 28px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 4px 13px rgb(0 0 0 / 25%);

  @include lg {
    font-size: 25px;
  }

  @include md {
    font-size: 18px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }

  @include tn {
    font-size: 12px;
  }
}

.title_box {
  position: relative;
  margin: 37px auto 13px;
  width: 90%;

  @include lg {
    align-items: flex-start;
    margin: 30px auto 13px;
  }

  @include md {
    margin: 20px auto 13px;
  }

  @include xs {
    margin: 15px auto 13px;
  }

  @include tn {
    margin: 12px auto 13px;
  }
}

.title_span0 {
  font: 600 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title_span1 {
  font: 500 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title_span2 {
  font: 600 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title_span3 {
  font: 500 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.hero_title1 {
  position: absolute;
  top: -35px;
  left: -12px;
  width: 100%;
  height: 59px;
  font: 700 48px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 13px rgb(0 0 0 / 25%);

  @include md {
    top: -14px;
    align-items: flex-start;
    font-size: 26px;
  }

  @include xs {
    top: -10px;
    font-size: 20px;
  }

  @include tn {
    top: -9px;
    font-size: 15px;
  }
}
