@use "../utils" as *;
@use "../global" as *;

.section2 {
  display: flex;
  flex-direction: column;
}

.content_box {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 55px auto 0;
  width: 70.21%;
  background-color: rgb(139 139 139 / 20.3%);

  @include xxl {
    margin: 50px auto 0;
  }

  @include xl {
    margin: 45px auto 0;
  }

  @include lg {
    margin: 40px auto 0;
    width: 90%;
  }

  @include md {
    margin: 20px auto 0;
  }

  @include xs {
    margin: 15px auto 0;
  }
}

.title4 {
  position: relative;
  margin: 29px auto 9px;
  width: 91.62%;
  font: 500 28px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 13px rgb(0 0 0 / 25%);

  @include lg {
    align-items: flex-start;
    font-size: 25px;
  }

  @include md {
    margin: 15px auto 9px;
    font-size: 18px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }

  @include tn {
    font-size: 12px;
  }
}

.hero_title2 {
  position: absolute;
  top: -31px;
  left: -12px;
  width: 60%;
  height: 59px;
  font: 700 48px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 13px rgb(0 0 0 / 25%);

  @include md {
    top: -18px;
    left: -8px;
    align-items: flex-start;
    font-size: 26px;
  }

  @include xs {
    top: -12px;
    left: -10px;
    font-size: 20px;
  }

  @include tn {
    top: -10px;
    font-size: 15px;
  }
}
