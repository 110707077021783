@use "../utils" as *;
@use "../global" as *;

.section3 {
  display: flex;
  flex-direction: column;
}

.image3 {
  margin: 53px auto 0;
  width: 1055px;
  outline: 12.782713890075684px solid rgb(255 255 255);
  outline-offset: -6.391356945037842px;
  aspect-ratio: 1.54;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));

  @include xxl {
    width: 73.26%;
    height: 92.82%;
  }

  @include md {
    margin: 4% auto 0;
    width: 96.56%;
  }
}
