@use "../utils" as *;
@use "../global" as *;

.section3 {
  display: flex;
  flex-direction: column;
}

.hero_title {
  display: flex;
  justify-content: center;
  margin: 26px auto 0;
  font: 600 48px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-align: center;
  text-shadow: 0 0 12px rgb(0 0 0 / 25%);

  @include xxl {
    align-items: flex-start;
    width: 100%;
    font-size: 46px;
  }

  @include md {
    font-size: 30px;
  }

  @include sm {
    font-size: 27px;
  }

  @include xxs {
    font-size: 22px;
  }

  @include tn {
    font-size: 19px;
  }
}
