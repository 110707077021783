@use "../utils" as *;
@use "../global" as *;

.section7 {
  display: flex;
  flex-direction: column;
}

.content_box3 {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 47px auto 55px;
  width: 70%;
  background-color: rgb(139 139 139 / 20.3%);

  @include xxl {
    width: 80%;
  }

  @include xl {
    margin: 40px auto 55px;
  }

  @include lg {
    margin: 35px auto 55px;
  }

  @include md {
    margin: 30px auto;
    width: 90%;
  }

  @include xs {
    margin: 25px auto 30px;
  }

  @include xxs {
    margin: 20px auto 30px;
  }
}

.title {
  font: italic 500 28px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 13px rgb(0 0 0 / 27%);

  @include lg {
    font-size: 25px;
  }

  @include md {
    font-size: 18px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }

  @include tn {
    font-size: 12px;
  }
}

.title_box {
  position: relative;
  margin: 46px auto 27px;
  width: 85.91%;

  @include lg {
    align-items: flex-start;
    margin: 35px auto 27px;
  }

  @include md {
    margin: 20px auto 27px;
  }

  @include xs {
    margin: 18px auto 27px;
  }

  @include xxs {
    margin: 15px auto 27px;
  }
}

.title_span0 {
  font: italic 500 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title_span1 {
  font: italic 600 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title_span2 {
  font: italic 500 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title_span3 {
  font: italic 600 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title_span4 {
  font: italic 500 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title_span5 {
  font: italic 600 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title_span6 {
  font: italic 500 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.hero_title1 {
  position: absolute;
  top: -30px;
  left: -14px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 59px;
  font: 700 48px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-align: right;
  text-shadow: 0 0 13px rgb(0 0 0 / 27%);

  @include xxxl {
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    font-size: 48px;
  }

  @include md {
    top: -16px;
    left: -18px;
    font-size: 26px;
  }

  @include xs {
    top: -12px;
    left: -16px;
    font-size: 20px;
  }

  @include xxs {
    top: -11px;
  }

  @include tn {
    top: -8px;
    left: -11px;
    font-size: 15px;
  }
}
