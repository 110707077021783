@use "../utils" as *;
@use "../global" as *;

.section8 {
  display: flex;
  flex-direction: column;
  margin-bottom: 55px;

  @include md {
    margin-bottom: 30px;
  }
}

.toast-success {

}

.box {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 53px 0 0 15.1%;
  width: 646px;
  filter: drop-shadow(0 0 12px rgb(0 0 0 / 25%));

  @include md {
    margin: 4% 0 0 8%;
    width: 84.11%;
  }

  @include sm {
    margin: 4% 0 0 7%;
  }

  @include xs {
    margin: 4% 0 0 6%;
  }

  @include xxs {
    margin: 4% 0 0 5%;
  }
}

.flex_col {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  margin: 60px 0 0 25px;
}

.flex_col1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex_col2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px 0;
  width: 98.55%;
}

.title1 {
  width: 89.54%;
  font: 600 29.18px/1.23 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include md {
    align-items: flex-start;
    font-size: 26px;
  }

  @include xs {
    font-size: 22px;
  }

  @include xxs {
    font-size: 16px;
  }
}

.rect4 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.rect41 {
  position: relative;
  min-height: 49px;
  background-color: rgb(247 247 247);
  border-radius: 24.32px;
  outline: 0.8105396032333374px solid rgb(30 30 30);
  outline-offset: -0.4052698016166687px;

  @include xxs {
    height: 30px;
    min-height: unset;
  }
}

.text_name {
  width: 100%;
  font: 29px/1.23 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  background-color: transparent;
  border: 0;
  padding: 0;
  vertical-align: top;

  @include md {
    font-size: 26px;
  }

  @include xs {
    font-size: 22px;
  }

  @include xxs {
    font-size: 16px;
  }
}

.text_name:focus {
  outline: none;
}

.text_name_box {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 95%;
  height: 100%;
}

.flex_col3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px 0;
  margin: 20px 0 0;
  width: 98.55%;
}

.text_email {
  width: 100%;
  font: 29px/1.23 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  background-color: transparent;
  border: 0;
  padding: 0;
  vertical-align: top;

  @include md {
    font-size: 26px;
  }

  @include xs {
    font-size: 22px;
  }

  @include xxs {
    font-size: 16px;
  }
}

.text_email:focus {
  outline: none;
}

.text_email_box {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 95%;
  height: 100%;

  @include xxs {
    top: unset;
    bottom: unset;
  }
}

.text_email_theme {
  width: 100%;
  font: 29px/1.23 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  background-color: transparent;
  border: 0;
  padding: 0;
  vertical-align: top;

  @include md {
    font-size: 26px;
  }

  @include xs {
    font-size: 22px;
  }

  @include xxs {
    font-size: 16px;
  }
}

.text_email_theme:focus {
  outline: none;
}

.text_email_theme_box {
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 95%;
  height: 100%;
}

.flex_col4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px 0;
  margin: 23px 0 0;
  width: 98.55%;
}

.title11 {
  width: 86.6%;
  font: 600 29.18px/1.23 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include md {
    align-items: flex-start;
    font-size: 26px;
  }

  @include xs {
    font-size: 22px;
  }

  @include xxs {
    font-size: 16px;
  }
}

.rect5 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.rect51 {
  position: relative;
  min-height: 410px;
  background-color: rgb(247 247 247);
  border-radius: 60.79px;
  outline: 0.8105396032333374px solid rgb(30 30 30);
  outline-offset: -0.4052698016166687px;

  @include xxs {
    height: 200px;
    min-height: unset;
    border-radius: 30px;
  }
}

.text_message {
  width: 100%;
  height: 100%;
  font: 29px/1.23 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  background-color: transparent;
  border: 0;
  padding: 0;
  vertical-align: top;
  resize: none;

  @include md {
    font-size: 26px;
  }

  @include xs {
    font-size: 22px;
  }

  @include xxs {
    font-size: 16px;
  }
}

.text_message:focus {
  outline: none;
}

.text_message_box {
  position: absolute;
  top: 5%;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 95%;
  height: 90%;

  @include md {
    align-items: flex-start;
  }
}

.btn {
  display: flex;
  justify-content: center;
  margin: 0 0 0 6.76%;
  width: 191px;
  max-width: 100%;
  font: 700 24.56px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-align: center;
  background-color: rgb(200 200 200);
  border-radius: 20.67px;
  padding: 5.5px 10px;

  @include md {
    margin: 0 auto 0 auto;
  }
}

.aws-btn {
  --button-default-height: 30px;
  --button-default-font-size: 12px;
  --button-default-border-radius: 25px;
  --button-horizontal-padding: 15px;
  --button-raise-level: 3px;
  --button-hover-pressure: 1;
  --transform-speed: 0.15s;
  --button-primary-color: #c8c8c8;
  --button-primary-color-dark: #464444;
  --button-primary-color-light: #000000;
  --button-primary-color-hover: #8b8484;
  --button-primary-color-active: #d2d1d1;
  --button-primary-border: 0px solid transparent;
}

.hero_title1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 47px;
  font: 700 38.91px/1.2 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include md {
    align-items: flex-start;
    font-size: 35px;
  }

  @include xs {
    font-size: 30px;
  }

  @include xxs {
    font-size: 22px;
  }
}
