@use "../utils" as *;
@use "../global" as *;

.section4 {
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
}

.content_box4 {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 100px auto 50px;
  width: 70%;
  background-color: rgb(139 139 139 / 20.3%);

  @include xxl {
    margin: 100px auto 40px;
    width: 80%;
  }

  @include xl {
    margin: 100px auto 35px;
  }

  @include lg {
    margin: 100px auto 30px;
  }

  @include md {
    margin: 40px auto 25px;
    width: 90%;
  }

  @include sm {
    margin: 35px auto 25px;
  }

  @include xxs {
    margin: 30px auto 20px;
  }
}

.flex_row {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 10px;
  margin: 20px auto 34px;
  width: 93.1%;

  @include xxl {
    column-gap: 20px;
  }

  @include md {
    flex-direction: column;
    gap: 20px;
  }
}

.flex_col {
  display: flex;
  flex-direction: column;
  width: 550px;
  min-width: 0;

  @include md {
    flex: 0 1 auto;
    order: 3;
    margin-top: unset;
    margin-bottom: unset;
    width: 100%;
  }
}

.box15 {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 9px;
  filter: drop-shadow(0 0 12px rgb(0 0 0 / 27%));
}

.flex_col1 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.big_title1 {
  margin: 0 11px 0 0;
  width: 100%;
  height: 42px;
  font: 600 34px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 13px rgb(0 0 0 / 25%);

  @include xxl {
    align-items: flex-start;
    font-size: 30px;
  }

  @include xl {
    font-size: 28px;
  }

  @include lg {
    height: 35px;
    font-size: 23px;
  }

  @include xxs {
    height: 25px;
    font-size: 20px;
  }

  @include tn {
    font-size: 15px;
  }
}

.box17 {
  display: flex;
  flex-direction: column;
  gap: 2px 0;
  margin: 6px 0 0 15px;
  filter: drop-shadow(0 0 13px rgb(0 0 0 / 25%));

  @include xl {
    margin: 0 0 0 10px;
  }
}

.medium_title3 {
  width: 100%;
  font: 500 25px/1.23 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include xxl {
    align-items: flex-start;
    font-size: 24px;
  }

  @include xl {
    font-size: 20px;
  }

  @include lg {
    font-size: 17px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }

  @include tn {
    font-size: 12px;
  }
}

.medium_title31 {
  width: 100%;
  font: 500 25px/1.23 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include xxl {
    align-items: flex-start;
    font-size: 24px;
  }

  @include xl {
    font-size: 20px;
  }

  @include lg {
    font-size: 17px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }

  @include tn {
    font-size: 12px;
  }
}

.medium_title32 {
  margin: 0 auto;
  width: 100%;
  font: 500 25px/1.23 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include xxl {
    align-items: flex-start;
    font-size: 24px;
  }

  @include xl {
    font-size: 20px;
  }

  @include lg {
    font-size: 17px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }

  @include tn {
    font-size: 12px;
  }
}

.box16 {
  display: flex;
  gap: 0 8px;
  margin: 15px 0 0 31px;
  width: 168px;
  filter: drop-shadow(0 0 12px rgb(255 255 255 / 99%));

  @include xs {
    margin: 10px 0 0 31px;
  }

  @include xxs {
    margin: 10px 0 0 32px;
  }

  @include tn {
    margin: 5px 0 0 32px;
  }
}

.subtitle2 {
  display: flex;
  min-width: 0;
  font: 400 21.89px/1.23 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(24 115 189);
  text-decoration: underline;

  @include lg {
    align-items: flex-start;
    font-size: 20px;
  }

  @include xxs {
    font-size: 18px;
  }

  @include tn {
    align-items: center;
    font-size: 15px;
  }
}

.image6 {
  width: 28px;
  min-width: 0;
  height: 27px;
}

.flex_col2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 14px 0 0 9px;
  width: 100%;

  @include lg {
    margin: 10px 0 0 9px;
  }
}

.big_title11 {
  width: 100%;
  height: 42px;
  font: 600 34px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 13px rgb(0 0 0 / 25%);

  @include xxl {
    align-items: flex-start;
    font-size: 30px;
  }

  @include xl {
    font-size: 28px;
  }

  @include lg {
    height: 35px;
    font-size: 23px;
  }

  @include xxs {
    height: 25px;
    font-size: 20px;
  }

  @include tn {
    font-size: 15px;
  }
}

.box14 {
  display: flex;
  flex-direction: column;
  width: 100%;
  filter: drop-shadow(0 0 13px rgb(0 0 0 / 25%));
}

.flex_col3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px 0;
  margin: 0 0 0 15px;
  width: 100%;

  @include xl {
    margin: 0 0 0 10px;
  }
}

.medium_title33 {
  width: 100%;
  font: 500 25px/1.23 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include xxl {
    align-items: flex-start;
    font-size: 24px;
  }

  @include xl {
    font-size: 20px;
  }

  @include lg {
    font-size: 17px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }
}

.medium_title34 {
  width: 100%;
  font: 500 26px/1.23 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include xxl {
    align-items: flex-start;
    font-size: 24px;
  }

  @include xl {
    font-size: 20px;
  }

  @include lg {
    font-size: 17px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }
}

.a1 {
  width: 100%;
}

.flex_row1 {
  display: flex;
  flex-direction: row;
  gap: 0 8px;
  margin: 14px 0 0 31px;
  width: 86.25%;

  @include xs {
    margin: 10px 0 0 31px;
  }

  @include tn {
    margin: 5px 0 0 32px;
  }
}

.subtitle21 {
  display: flex;
  justify-content: center;
  margin: 1px 0 0;
  min-width: 0;
  font: 400 21.89px/1.23 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(24 115 189);
  text-align: center;
  text-decoration: underline;

  @include lg {
    align-items: flex-start;
    font-size: 20px;
  }

  @include xxs {
    font-size: 18px;
  }

  @include tn {
    align-items: center;
    font-size: 15px;
  }
}

.image5 {
  margin: 0 0 1px;
  width: 28px;
  min-width: 0;
  height: 27px;
  filter: drop-shadow(0 0 12px rgb(255 255 255 / 100%));
}

.flex_col4 {
  display: flex;
  flex-direction: column;
  margin: 14px 0 0 9px;
  width: 100%;

  @include lg {
    margin: 10px 0 0 9px;
  }
}

.box10 {
  display: flex;
  flex-direction: column;
  width: 100%;
  filter: drop-shadow(0 0 13px rgb(0 0 0 / 25%));
}

.big_title12 {
  width: 100%;
  height: 42px;
  font: 600 34.34px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 13px rgb(0 0 0 / 25%);

  @include xxl {
    align-items: flex-start;
    font-size: 30px;
  }

  @include xl {
    font-size: 28px;
  }

  @include lg {
    height: 35px;
    font-size: 23px;
  }

  @include xxs {
    height: 25px;
    font-size: 20px;
  }

  @include tn {
    font-size: 15px;
  }
}

.box18 {
  display: flex;
  flex-direction: column;
  margin: 3px 0 0;
  filter: drop-shadow(0 0 13px rgb(0 0 0 / 25%));
}

.flex_col5 {
  display: flex;
  flex-direction: column;
  gap: 2px 0;
  margin: 0 0 0 15px;

  @include xl {
    margin: 0 0 0 10px;
  }
}

.medium_title35 {
  width: 100%;
  font: 500 25px/1.23 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 13px rgb(0 0 0 / 25%);

  @include xxl {
    align-items: flex-start;
    font-size: 24px;
  }

  @include xl {
    font-size: 20px;
  }

  @include lg {
    font-size: 17px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }
}

.medium_title36 {
  width: 100%;
  font: 500 25px/1.23 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 13px rgb(0 0 0 / 25%);

  @include xxl {
    align-items: flex-start;
    font-size: 24px;
  }

  @include lg {
    font-size: 17px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }
}

.medium_title37 {
  width: 100%;
  font: 500 25px/1.23 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 13px rgb(0 0 0 / 25%);

  @include xxl {
    align-items: flex-start;
    font-size: 24px;
  }

  @include xl {
    font-size: 20px;
  }

  @include lg {
    font-size: 17px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }
}

.box7 {
  display: flex;
  flex-direction: column;
  margin: 14px 0 0 32px;
  width: 167px;
  filter: drop-shadow(0 0 12px rgb(0 0 0 / 27%));

  @include xs {
    margin: 10px 0 0 32px;
  }

  @include tn {
    margin: 5px 0 0 32px;
  }
}

.box8 {
  display: flex;
  gap: 0 7px;
  filter: drop-shadow(0 0 12px rgb(255 255 255 / 100%));
}

.subtitle22 {
  display: flex;
  min-width: 0;
  font: 400 21.89px/1.23 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(24 115 189);
  text-decoration: underline;
  text-shadow: 0 0 12px rgb(255 255 255 / 100%);

  @include lg {
    align-items: flex-start;
    font-size: 20px;
  }

  @include xxs {
    font-size: 18px;
  }

  @include tn {
    align-items: center;
    font-size: 15px;
  }
}

.image51 {
  width: 28px;
  min-width: 0;
  height: 27px;
  filter: drop-shadow(0 0 12px rgb(255 255 255 / 100%));
}

.box11 {
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0 0 13px rgb(0 0 0 / 25%));
}

.big_title13 {
  width: 100%;
  height: 42px;
  font: 600 34.34px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include xxl {
    align-items: flex-start;
    font-size: 30px;
  }

  @include xl {
    font-size: 28px;
  }

  @include lg {
    height: 35px;
    font-size: 23px;
  }

  @include xxs {
    height: 25px;
    font-size: 20px;
  }

  @include tn {
    font-size: 15px;
  }
}

.box9 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px 0;
  margin: 3px 0 0 15px;
  filter: drop-shadow(0 0 13px rgb(0 0 0 / 25%));

  @include xl {
    margin: 3px 0 0 10px;
  }
}

.medium_title2 {
  width: 100%;
  font: 500 25px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include xxl {
    align-items: flex-start;
    font-size: 24px;
  }

  @include xl {
    font-size: 20px;
  }

  @include lg {
    font-size: 17px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }
}

.medium_title21 {
  width: 100%;
  font: 500 25px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include xxl {
    align-items: flex-start;
    font-size: 24px;
  }

  @include xl {
    font-size: 20px;
  }

  @include lg {
    font-size: 17px;
  }

  @include xxs {
    font-size: 13px;
  }
}

.box71 {
  display: flex;
  flex-direction: column;
  margin: 8px 0 0 31px;
  width: 167px;
  filter: drop-shadow(0 0 12px rgb(0 0 0 / 27%));

  @include tn {
    margin: 5px 0 0 32px;
  }
}

.big_title14 {
  margin: 0 13px 0 0;
  height: 42px;
  font: 600 34.34px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 13px rgb(0 0 0 / 25%);

  @include xxl {
    align-items: flex-start;
    font-size: 30px;
  }

  @include xl {
    font-size: 28px;
  }

  @include lg {
    height: 35px;
    font-size: 23px;
  }

  @include xxs {
    height: 25px;
    font-size: 20px;
  }

  @include tn {
    font-size: 15px;
  }
}

.medium_title22 {
  margin: 0 0 0 15px;
  width: 100%;
  font: 500 25px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include xxl {
    align-items: flex-start;
    font-size: 24px;
  }

  @include xl {
    margin: 0 0 0 10px;
    font-size: 20px;
  }

  @include lg {
    font-size: 17px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }
}

.box72 {
  display: flex;
  flex-direction: column;
  margin: 19px 0 0 31px;
  width: 86.25%;
  filter: drop-shadow(0 0 12px rgb(0 0 0 / 27%));

  @include xs {
    margin: 10px 0 0 32px;
  }

  @include tn {
    margin: 5px 0 0 32px;
  }
}

.flex_col6 {
  display: flex;
  flex-direction: column;
  gap: 51px 0;
  width: 673px;
  min-width: 0;

  @include md {
    flex-direction: row;
    align-items: center;
    column-gap: 30px;
    flex: 0 1 auto;
    order: 1;
    margin-top: unset;
    margin-bottom: unset;
    width: 100%;
  }

  @include xs {
    flex-direction: column;
    gap: 30px;
  }
}

.image8 {
  width: 100%;
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -6.389999866485596px;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));

  @include md {
    flex: 0 1 auto;
    order: 3;
    margin-top: unset;
    margin-right: unset;
    margin-bottom: unset;
    margin-left: unset;
    width: calc(100% - unset);
  }

  @include xs {
    width: 100%;
  }
}

.image81 {
  width: 100%;
  outline: 12.779999732971191px solid rgb(255 255 255);
  outline-offset: -6.389999866485596px;
  filter: drop-shadow(0 0 11px rgb(0 0 0 / 25%));

  @include md {
    display: none;
    //display: flex;
    flex: 0 1 auto;
    order: 1;
    margin-top: unset;
    margin-right: unset;
    margin-bottom: unset;
    margin-left: unset;
    width: calc(100% - unset);
  }

  @include xs {
    width: 100%;
  }
}

.hero_title1 {
  position: absolute;
  top: -82px;
  left: -15px;
  width: 215px;
  height: 59px;
  font: 700 48px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 13px rgb(0 0 0 / 27%);

  @include md {
    top: -16px;
    align-items: flex-start;
    width: 100%;
    font-size: 26px;
  }

  @include xs {
    font-size: 20px;
  }

  @include tn {
    font-size: 15px;
  }
}

.placeholder1 {
  position: absolute;
  top: 598px;
  left: 716px;
  width: 1px;
  height: 1px;
}
