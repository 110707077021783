@use "../utils" as *;
@use "../global" as *;

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.cover1 {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flex_col {
  position: relative;
  display: flex;
  flex-direction: column;
}
