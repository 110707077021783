@use "../utils" as *;
@use "../global" as *;

.section4 {
  display: flex;
  flex-direction: column;
}

.section41 {
  display: flex;
  flex-direction: column;
  margin: 53px auto 0;
  width: 96.56%;
  background-color: rgb(139 139 139 / 20.3%);

  @include md {
    margin: 4% auto 0;
  }
}

.title {
  font: 500 28px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 12px rgb(0 0 0 / 25%);

  @include lg {
    font-size: 25px;
  }

  @include md {
    font-size: 18px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }

  @include tn {
    font-size: 12px;
  }
}

.title_box {
  margin: 13px auto 10px;
  width: 93.32%;

  @include xxl {
    width: 97.27%;
  }

  @include lg {
    align-items: flex-start;
  }
}

.title_span0 {
  font: 500 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title_span1 {
  font: 600 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title_span2 {
  font: 500 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}
