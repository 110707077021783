@use "../utils" as *;
@use "../global" as *;

.section8 {
  display: flex;
  flex-direction: column;
  z-index: 2024;
}

.content_box4 {
  display: flex;
  flex-direction: column;
  background-color: rgb(255 255 255);
  box-shadow: 0 -5px 22px 5px rgb(0 0 0 / 25%);
}

.flex_col {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  width: 80%;

  @include md {
    width: 90%;
  }

  @include xxs {
    width: 95%;
  }

  @include tn {
    width: 99%;
  }
}

.subtitle {
  width: 100%;
  font: 600 20px/1.2 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include xxs {
    align-items: flex-start;
    font-size: 15px;
  }
}

.flex_row {
  display: flex;
  gap: 0 8px;
  margin: 19px 0 0;
  width: 98.35%;

  @include xs {
    column-gap: 4px;
  }

  @include xxs {
    margin: 10px 0 0;
  }
}

.image {
  margin: 3px 0 0;
  width: 28px;
  min-width: 0;
  height: 28px;

  @include md {
    margin-top: unset;
    width: 22px;
    height: 22px;
  }

  @include xs {
    width: 14px;
    height: 14px;
  }
}

.subtitle1 {
  margin: 0 0 5px;
  min-width: 0;
  font: 500 21.34px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  letter-spacing: -1.1px;

  @include md {
    align-items: flex-start;
    font-size: 18px;
  }

  @include xs {
    font-size: 12px;
  }
}

.flex_row1 {
  display: flex;
  align-items: center;
  gap: 0 3px;
  margin: 7px 0 0;
  width: 99.72%;

  @include xs {
    column-gap: 3px;
  }

  @include xxs {
    margin: 0;
  }
}

.image1 {
  width: 40px;
  min-width: 0;
  height: 36px;

  @include md {
    width: 30px;
    height: 30px;
  }

  @include xs {
    width: 20px;
    height: 20px;
  }
}

.subtitle11 {
  min-width: 0;
  font: 500 21.34px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include md {
    align-items: flex-start;
    font-size: 18px;
  }

  @include xs {
    font-size: 12px;
  }
}

.flex_row2 {
  display: flex;
  align-items: center;
  gap: 0 4px;
  margin: 15px 0 0;
  width: 100%;

  @include lg {
    margin: 0;
  }

  @include md {
    column-gap: 4px;
  }

  @include xs {
    column-gap: 2px;
  }
}

.hero_title {
  min-width: 0;
  font: 500 39.77px/1.2 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include xs {
    align-items: flex-start;
    width: 20px;
    height: 30px;
    font-size: 24px;
  }
}

.medium_title {
  display: flex;
  min-width: 0;
  font: 500 24px/1.2 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include md {
    align-items: flex-start;
    font-size: 18px;
  }

  @include sm {
    align-items: center;
    font-size: 16px;
  }

  @include xs {
    font-size: 12px;
  }

  @include tn {
    font-size: 10px;
  }
}

.medium_title1 {
  display: flex;
  min-width: 0;
  font: 700 24px/1.2 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include md {
    align-items: flex-start;
    font-size: 18px;
  }

  @include sm {
    align-items: center;
    font-size: 16px;
  }

  @include xs {
    font-size: 12px;
  }

  @include tn {
    font-size: 10px;
  }
}

.medium_title2 {
  display: flex;
  min-width: 0;
  font: 500 24px/1.2 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  letter-spacing: -1.4px;

  @include md {
    align-items: flex-start;
    font-size: 18px;
  }

  @include sm {
    align-items: center;
    font-size: 16px;
  }

  @include xs {
    font-size: 12px;
  }

  @include tn {
    font-size: 10px;
  }
}

a[href^="tel:"] {
  color: #000000;
  text-decoration: none;
}

a[href^="mailto:"] {
  color: #000000;
  text-decoration: none;
}
