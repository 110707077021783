@use "../utils" as *;
@use "../global" as *;

.section2 {
  display: flex;
  flex-direction: column;
}

.content_box1 {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 50px auto 0;
  width: 70.21%;
  background-color: rgb(139 139 139 / 20.3%);

  @include xl {
    margin: 45px auto 0;
  }

  @include lg {
    margin: 35px auto 0;
  }

  @include md {
    margin: 20px auto 0;
    width: 90%;
  }

  @include xxs {
    margin: 15px auto 0;
  }
}

.title1 {
  position: relative;
  margin: 27px 0 9px 20px;
  width: 77.45%;
  font: 500 28px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 12px rgb(0 0 0 / 25%);

  @include lg {
    align-items: flex-start;
    font-size: 25px;
  }

  @include md {
    margin: 15px 0 9px 20px;
    font-size: 18px;
  }

  @include xs {
    margin: 12px 0 9px 20px;
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }

  @include tn {
    font-size: 12px;
  }
}

.hero_title1 {
  position: absolute;
  top: -33px;
  left: -12px;
  width: 527px;
  height: 59px;
  font: 700 48px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 12px rgb(0 0 0 / 25%);

  @include xxl {
    width: 60%;
  }

  @include md {
    top: -18px;
    align-items: flex-start;
    font-size: 26px;
  }

  @include xs {
    top: -15px;
    font-size: 20px;
  }

  @include tn {
    top: -10px;
    font-size: 15px;
  }
}
