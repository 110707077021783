@use "../utils" as *;
@use "../global" as *;

.section2 {
  display: flex;
  flex-direction: column;
}

.content_box {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 45px auto 0;
  width: 70.21%;
  background-color: rgb(139 139 139 / 20.3%);

  @include xxl {
    margin: 40px auto 0;
    width: 80%;
  }

  @include lg {
    margin: 35px auto 0;
  }

  @include md {
    margin: 30px auto 0;
    width: 90%;
  }

  @include xs {
    margin: 25px auto 0;
  }
}

.hero_title1 {
  position: absolute;
  top: -30px;
  left: -15px;
  width: 100%;
  height: 58px;
  font: 700 48px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 12px rgb(0 0 0 / 25%);

  @include md {
    top: -15px;
    align-items: flex-start;
    font-size: 26px;
  }

  @include xs {
    top: -14px;
    font-size: 20px;
  }

  @include tn {
    top: -9px;
    font-size: 15px;
  }
}

.title1 {
  font: 500 28px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include lg {
    font-size: 25px;
  }

  @include md {
    font-size: 18px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    font-size: 13px;
  }

  @include tn {
    font-size: 12px;
  }
}

.title1_box {
  position: relative;
  margin: 29px 0 7px 20px;
  width: 85.16%;

  @include lg {
    align-items: flex-start;
  }

  @include md {
    margin: 15px 0 7px 20px;
    width: 90%;
  }

  @include xs {
    margin: 12px 0 7px 20px;
  }
}

.title1_span0 {
  font: 500 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title1_span1 {
  font: 600 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title1_span2 {
  font: 500 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}
