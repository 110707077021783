@use "../utils" as *;
@use "../global" as *;

.section2 {
  display: flex;
  flex-direction: column;
}

.content_box {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 50px auto 0;
  width: 70.21%;
  background-color: rgb(139 139 139 / 20.3%);

  @include xxl {
    margin: 45px auto 0;
  }

  @include xl {
    margin: 35px auto 0;
  }

  @include md {
    margin: 30px auto 0;
    width: 90%;
  }

  @include sm {
    margin: 25px auto 0;
  }

  @include xxs {
    margin: 20px auto 0;
  }

  @include tn {
    margin: 15px auto 0;
  }
}

.title1 {
  position: relative;
  margin: 37px 0 6px 20px;
  width: 83.53%;
  font: 500 28px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 12px rgb(0 0 0 / 25%);

  @include lg {
    align-items: flex-start;
    font-size: 25px;
  }

  @include md {
    margin: 20px 0 6px 20px;
    font-size: 18px;
  }

  @include sm {
    margin: 18px 0 6px 20px;
  }

  @include xs {
    margin: 16px 0 6px 20px;
    font-size: 15px;
  }

  @include xxs {
    margin: 15px 0 6px 20px;
    font-size: 13px;
  }

  @include tn {
    font-size: 12px;
  }
}

.hero_title1 {
  position: absolute;
  top: -30px;
  left: -12px;
  width: 60%;
  height: 59px;
  font: 700 48px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 12px rgb(0 0 0 / 25%);

  @include md {
    top: -18px;
    align-items: flex-start;
    font-size: 26px;
  }

  @include xs {
    top: -14px;
    font-size: 20px;
  }

  @include tn {
    top: -9px;
    font-size: 15px;
  }
}
