@use "../utils" as *;
@use "../global" as *;

.section3 {
  display: flex;
  flex-direction: column;
}

.flex_row {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  margin: 55px auto 0;
  width: 1328px;
  padding: 0;

  @include xxl {
    margin: 50px auto 0;
    width: 92.22%;
  }

  @include xl {
    column-gap: 40px;
    margin: 45px auto 0;
  }

  @include lg {
    margin: 40px auto 0;
  }

  @include md {
    flex-direction: column;
    align-items: center;
    gap: 50px 40px;
    margin: 35px auto 0;
    width: 90%;
  }

  @include xs {
    margin: 30px auto 0;
  }
}

.flex_col {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px 0;
  width: 622px;
  min-width: 0;

  @include md {
    margin-top: unset;
    margin-bottom: unset;
    width: 100%;
  }

  @include xs {
    height: 100%;
  }
}

.image {
  width: 100%;
  outline: 18.400833129882812px solid rgb(255 255 255);
  outline-offset: -9.200416564941406px;
  filter: drop-shadow(0 0 15px rgb(0 0 0 / 25%));
}

.big_title {
  display: flex;
  justify-content: center;
  font: 600 36px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-align: center;
  text-shadow: 0 0 13px rgb(0 0 0 / 25%);

  @include md {
    align-items: flex-start;
    font-size: 26px;
  }

  @include xs {
    font-size: 20px;
  }
}

.flex_col1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px 0;
  width: 622px;
  min-width: 0;

  @include md {
    margin-top: unset;
    margin-bottom: unset;
    width: 100%;
  }
}

.image1 {
  width: 100%;
  outline: 18.400835037231445px solid rgb(255 255 255);
  outline-offset: -9.200417518615723px;
  filter: drop-shadow(0 0 15px rgb(0 0 0 / 25%));
}
