@use "../utils" as *;
@use "../global" as *;

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  overflow: hidden;
}

.cover {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flex_col {
  position: relative;
  display: flex;
  flex-direction: column;
}

.section2 {
  width: 100%;
  aspect-ratio: 3.34;
  box-shadow: 0 0 22px 5px rgb(0 0 0 / 25%);
}
