@use "../utils" as *;
@use "../global" as *;

.section5 {
  display: flex;
  flex-direction: column;
}

.section51 {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 70%;
  background-color: rgb(139 139 139 / 20.3%);

  @include xxl {
    width: 80%;
  }

  @include md {
    width: 90%;
  }
}

.flex_col {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1px 0;
  margin: 15px auto 13px;
  width: 93.47%;
}

.box6 {
  display: flex;
  flex-direction: column;
  gap: 12px 0;
  width: 100%;
  filter: drop-shadow(0 0 13px rgb(0 0 0 / 27%));
}

.hero_title4 {
  font: 600 40.42px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include xl {
    align-items: flex-start;
    font-size: 37px;
  }

  @include lg {
    font-size: 31px;
  }

  @include md {
    font-size: 27px;
  }

  @include sm {
    font-size: 20px;
  }

  @include xs {
    width: 80%;
  }

  @include xxs {
    width: 90%;
  }

  @include tn {
    font-size: 15px;
  }
}

.hero_title3 {
  font: 600 80.83px/0.99 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);

  @include xxl {
    align-items: flex-start;
    width: 100%;
    font-size: 73px;
  }

  @include xl {
    font-size: 63px;
  }

  @include lg {
    font-size: 52px;
  }

  @include md {
    font-size: 45px;
  }

  @include sm {
    font-size: 37px;
  }

  @include xs {
    font-size: 30px;
  }

  @include xxs {
    font-size: 24px;
  }

  @include tn {
    font-size: 20px;
  }
}

.flex_row {
  display: flex;
  align-items: center;
  gap: 0 7px;
  width: 100%;
}

.hero_title2 {
  min-width: 0;
  font: 500 44.14px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-decoration: underline;

  @include md {
    align-items: flex-start;
    font-size: 35px;
  }

  @include sm {
    font-size: 30px;
  }

  @include xxs {
    font-size: 28px;
  }
}

.image4 {
  width: 75px;
  min-width: 0;
  height: 74px;

  @include md {
    width: 55px;
    height: 55px;
  }

  @include sm {
    width: 45px;
    height: 45px;
  }

  @include xxs {
    width: 40px;
    height: 40px;
  }
}
