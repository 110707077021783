@use "../utils" as *;
@use "../global" as *;

.main {
  position: relative;
  display: flex;
  flex-direction: column;
}

.cover {
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.flex_col {
  position: relative;
  display: flex;
  flex-direction: column;
}
