@use "../utils" as *;
@use "../global" as *;

.section2 {
  display: flex;
  flex-direction: column;
}

.content_box {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 55px auto 0;
  width: 96.56%;
  background-color: rgb(139 139 139 / 20.3%);

  @include xxl {
    margin: 50px auto 0;
  }

  @include xl {
    margin: 40px auto 0 1.72%;
  }

  @include md {
    margin: 25px auto 0 1.72%;
  }

  @include xs {
    margin: 20px auto 0 1.72%;
  }

  @include xxs {
    margin: 15px auto 0 1.72%;
  }
}

.title {
  font: 500 28px/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 12px rgb(0 0 0 / 25%);

  @include xl {
    font-size: 28px;
  }

  @include lg {
    font-size: 25px;
  }

  @include md {
    font-size: 18px;
  }

  @include xs {
    font-size: 15px;
  }

  @include xxs {
    text-align: justify;
    font-size: 13px;
  }

  @include tn {
    font-size: 12px;
  }
}

.title_box {
  position: relative;
  margin: 35px 18px 20px 20px;

  @include xxl {
    width: 97.27%;
  }

  @include xl {
    align-items: flex-start;
    margin: 35px 1.34% 20px 1.48%;
    width: 97.18%;
  }

  @include md {
    margin: 20px 1.34% 20px 1.48%;
  }

  @include sm {
    margin: 15px 1.34% 20px 1.48%;
  }
}

.title_span0 {
  font: 500 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title_span1 {
  font: 600 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.title_span2 {
  font: 500 1em/1.21 "Montserrat Alternates", Helvetica, Arial, serif;
}

.hero_title {
  position: absolute;
  top: -34px;
  left: -12px;
  width: 470px;
  height: 59px;
  font: 700 48px/1.22 "Montserrat Alternates", Helvetica, Arial, serif;
  color: rgb(0 0 0);
  text-shadow: 0 0 12px rgb(0 0 0 / 25%);

  @include xxl {
    width: 470.05px;
  }

  @include md {
    top: -19px;
    left: -10px;
    align-items: flex-start;
    width: 270px;
    height: 30px;
    font-size: 26px;
  }

  @include sm {
    left: -5px;
  }

  @include xs {
    top: -11px;
    width: 210px;
    font-size: 20px;
  }

  @include tn {
    top: -8px;
    left: -3px;
    width: 150px;
    font-size: 15px;
  }
}
