@use "../utils" as *;
@use "../global" as *;

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  width: auto;
  background-color: rgb(255 255 255);

  @include mq-between(xxxl, max) {
    height: auto;
  }

  @include xxl {
    width: unset;
    height: unset;
    min-height: unset;
  }

  @include lg {
    width: auto;
    height: auto;
  }
}

.cover {
  position: absolute;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.flex_col {
  position: relative;
  display: flex;
  flex-direction: column;

  @include mq-between(xxxl, max) {
    width: 100%;
    height: 100%;
  }
}
